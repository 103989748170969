import React, { createContext, forwardRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const LISTBOX_PADDING = 8;

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

export const VirtualizedListbox = forwardRef(function VirtualizedListbox(props, ref) {
  const { children, loadMoreItems, isItemLoaded, ...other } = props;

  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemSize = smUp ? 48 : 36;
  const height = itemSize * Math.min(itemCount, 10) + 2 * LISTBOX_PADDING;

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <List
              itemData={itemData}
              height={height}
              itemCount={itemCount}
              itemSize={(index) => itemSize}
              onItemsRendered={onItemsRendered}
              outerElementType={OuterElementType}
              innerElementType="ul"
              ref={ref}
              width="100%"
            >
              {renderRow}
            </List>
          )}
        </InfiniteLoader>
      </OuterElementContext.Provider>
    </div>
  );
});
