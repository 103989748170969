import emptySplitApi from "./emptySplitApi";

export const checkinApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCheckin: builder.query({
            query: (id) => {
                return {
                    url: `checkin/${id}`,
                    method: "GET",
                };
            },
            transformResponse: (response) => {
                return response.data;
            },
        }),
        createCheckin: builder.mutation({
            query({ id, ...rest }) {
                delete rest.ageYears;
                delete rest.ageMonths;
                delete rest.hospitalData;
                return {
                    url: `checkin/${id}`,
                    method: "PUT",
                    body: rest,
                };
            },
            transformResponse: (response) => {
                return response.data;
            },
        }),
        getRdvm: builder.query({
            query: ({ hospitalId, searchTerm, limit, offset }) => {
                return {
                    url: `checkin/rdvm/search?hospital_id=${hospitalId}&search_term=${searchTerm}&limit=${limit}&offset=${offset}`,
                    method: "GET",
                };
            },
            transformResponse: (response) => response.data,
        }),
        getSpecies: builder.query({
            query: () => {
                return {
                    url: `pims/species`,
                    method: "GET",
                };
            },
            transformResponse: (response) => {
                return response.data;
            },
        }),
        getBreeds: builder.query({
            query: () => {
                return {
                    url: `pims/breed`,
                    method: "GET",
                };
            },
            transformResponse: (response) => {
                return response.data;
            },
        })
    }),
});

export const {
    useGetCheckinQuery,
    useCreateCheckinMutation,
    useLazyGetRdvmQuery,
    useGetSpeciesQuery,
    useGetBreedsQuery
} = checkinApi;
